import API from "../../helpers/API";

export default {
  actions: {
    getAllMetadata: async (_, payload = null) => {
      return API.getAllWithDispatch(
        "getMetadata",
        payload,
        "createdAt",
        "desc"
      );
    },
    getMetadata: async (_, payload) => {
      let url = `xviewmetadata?page=${payload.page ?? 1}`;
      url += `&bestScalingRun=${payload.bestScalingRun ? 1 : 0}`;

      if (payload.serialNumber) {
        url += `&serialNumber=${payload.serialNumber}`;
      }
      if (payload.projectDate) {
        url += `&projectDate=${payload.projectDate}`;
      }
      if (payload.downloadDate) {
        url += `&downloadDate=${payload.downloadDate}`;
      }
      if (payload.orderBy) {
        url += `&order[${payload.orderBy}]=${payload.orderDirection ?? "asc"}`;
      }

      return API.getMapped(url);
    },
    downloadMetadata: async (_, id) => {
      return API.download(`xviewmetadata/${id}/download`);
    },
    exportMetadata: async (_, payload) => {
      let url = "xviewmetadata/export?";
      url += `order[createdAt]=desc`;
      url += `&bestScalingRun=1`;

      if (payload.includeDoubleUploads) {
        url += `&includeDoubleUploads=1`;
      }
      if (payload.from) {
        url += `&createdAt[after]=${payload.from}`;
      }
      if (payload.till) {
        url += `&createdAt[before]=${payload.till}`;
      }
      if (payload.clients) {
        url += payload.clients
          .map((client) => `&client.id[]=${client}`)
          .join("");
      }
      if (payload.serialNumbers) {
        url += payload.serialNumbers
          .map((serialNumber) => `&serialNumber[]=${serialNumber}`)
          .join("");
      }
      if (payload.products) {
        url += payload.products
          .map((product) => `&product.id[]=${product}`)
          .join("");
      }
      if (payload.delimiter) {
        url += `&delimiter=${payload.delimiter}`;
      }

      return API.download(url);
    },
    getMetadataFilters: async () => {
      return API.get("xviewmetadata/export/filters?bestScalingRun=1");
    },
  },
};
